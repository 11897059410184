import React, { useEffect, useState } from 'react'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useOs } from 'src/lib/hooks/UseOS'
import { handleHotkey } from 'src/Util'

import GlobalSearchButton from './GlobalSearchButton'

type GenericEvent = Pick<Event, 'preventDefault'>

export type GlobalSearchModalClick = (
  trackerName: string,
) => (event?: GenericEvent) => void

const GlobalSearch = ({ isMobile, isHomePage = false }) => {
  const [openGlobalSearchModal, setOpenGlobalSearchModal] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const { trackEvent } = useAnalytics()
  const os = useOs()

  const toggleGlobalSearchModal: GlobalSearchModalClick =
    (trackerName) => (event) => {
      if (!openGlobalSearchModal) {
        setOpenGlobalSearchModal(true)
        trackEvent('Search', trackerName)
      } else {
        handleCloseGlobalSearch(event)
      }
    }

  const handleCloseGlobalSearch = (event?: GenericEvent) => {
    event?.preventDefault()
    setSearchVal('')
    setOpenGlobalSearchModal(false)
    trackEvent('Search', 'GSB - close search')
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isHomePage) {
        return
      }
      if (os === 'linux') {
        handleHotkey(event, 'm', toggleGlobalSearchModal('Hotkey'), os)
      } else {
        handleHotkey(event, 'k', toggleGlobalSearchModal('Hotkey'), os)
      }
    }
    if (!isMobile) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [os, toggleGlobalSearchModal])

  return (
    <>
      <GlobalSearchButton
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        isMobile={isMobile}
        isHomePage={isHomePage}
        openGlobalSearchModal={openGlobalSearchModal}
        openGlobalSearch={toggleGlobalSearchModal}
        handleCloseGlobalSearch={handleCloseGlobalSearch}
      />
    </>
  )
}
export default GlobalSearch
