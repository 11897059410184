import React, { FC } from 'react'

import {
  ArrowTopRightOnSquareIcon,
  CircleStackIcon,
  ClipboardDocumentListIcon,
  CodeBracketIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  LinkIcon,
  MapIcon,
  NewspaperIcon,
  PhoneIcon,
  PuzzlePieceIcon,
  RectangleStackIcon,
  Squares2X2Icon,
  TagIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline'
import {
  Avatar,
  Breadcrumbs,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { SEARCH_RESULT_TYPE } from 'api/src/common/enums'
import { without } from 'ramda'
import { useLocalStorage } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'

import { SearchResult } from 'src/components/GlobalSearch/GlobalSearchCell/GlobalSearchCell'
import {
  categories,
  GoogleSuggestionsContent,
} from 'src/components/GlobalSearch/GlobalSearchTabs'
import Empty from 'src/components/Library/Empty'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import UserAvatar from '../UserAvatar/UserAvatar'

interface GlobalSearchResultsProps {
  data: SearchResult[]
  query: string
  handleClose: () => void
  tabVal: string
  hideTabLabel?: boolean
}

interface ListContentProps {
  data: SearchResult[]
  value: string
  query: string
  category: {
    icon: JSX.Element
    name: string
    value: string
  }
  handleClose?: () => void
  isMobile?: boolean
  hideTabLabel?: boolean
}

interface HighlightedTitleText {
  text: string
  query: string
}

interface HighlightedDescriptionText {
  text: string
  query: string
}

const ListContent: FC<ListContentProps> = ({
  data,
  value,
  query,
  category,
  handleClose,
  hideTabLabel = false,
}) => {
  const { trackEvent } = useAnalytics()
  const { currentUser } = useAuth()

  const [recentCardIds, setRecentCards] = useLocalStorage(
    `memberId-${currentUser.membershipData?.id}-recentCardIds`,
    [],
  )

  const isMobile = useMediaQuery('(max-width: 640px)')
  if (data.length === 0 && value === 'all') return null

  const dataFiltered = data?.filter(
    (item) => item.__typename === value || value === 'all',
  )

  interface SearchResultWithUrl extends SearchResult {
    url: string
    breadcrumb: Array<string>
    avatarUrl: string
    sectionName: string
    phone: string
    email: string
    learnerType: string
    courseId: number
    activityId: number
    kbType: string
    articleText: string
    documentText: string
    isEmbed: boolean
    sectionId: number
  }

  const handleCategoryNavigation = ({
    __typename,
    entityId,
    handleClose,
    index,
    url,
    trackEvent,
    learnerType,
    courseId,
    activityId,
    isEmbed,
    sectionId,
  }) => {
    if (__typename === SEARCH_RESULT_TYPE.MEMBERSHIP) {
      navigate(routes.informerDirectoryMember({ membershipId: entityId }))
    } else if (__typename === SEARCH_RESULT_TYPE.COURSE) {
      if (learnerType === 'CATEGORY') {
        navigate(routes.learnerCategoryWithId({ id: entityId }))
      } else if (learnerType === 'COURSE') {
        navigate(routes.learnerCourse({ id: entityId }))
      } else if (learnerType === 'ACTIVITY') {
        navigate(
          routes.learnerCourseActivity({
            id: courseId,
            activityId: entityId,
          }),
        )
      } else if (learnerType === 'TASK') {
        navigate(
          routes.learnerCourseTask({
            id: courseId,
            activityId: activityId,
            taskId: entityId,
          }),
        )
      }
    } else if (__typename === SEARCH_RESULT_TYPE.HUBDASHLAYOUT) {
      navigate(routes.hubDashWithId({ layoutId: entityId }))
    } else if (__typename === SEARCH_RESULT_TYPE.PROCESS_MAP) {
      navigate(routes.reactMapById({ id: entityId }))
    } else if (__typename === SEARCH_RESULT_TYPE.KNOWLEDGE_BASE) {
      if (url) {
        openWindowWithBlockCheck(url, '_blank')
      } else {
        navigate(routes.knowledgeWithId({ id: entityId }))
      }
    } else if (__typename === SEARCH_RESULT_TYPE.HOME_LINKS) {
      if (isEmbed) {
        navigate(
          routes.embed({
            sectionId: sectionId ?? 0,
            cardId: entityId ?? 0,
          }),
        )
      } else if (url) {
        openWindowWithBlockCheck(url, '_blank')
      }
    } else if (__typename === 'CATEGORY') {
      navigate(routes.learnerCategories({ id: entityId }))
    }

    // track what kind of learner type the user clicks
    // as well as how far down the list they have to go
    const searchIndex = learnerType ? learnerType + ' ' + index : index

    trackEvent('Search', 'GS click item', {
      searchType: __typename,
      searchIndex: searchIndex,
    })

    handleClose()
  }

  const HighlightedTitleText: FC<HighlightedTitleText> = ({ text, query }) => (
    <>
      {text?.toLowerCase()?.includes(query) ? (
        <span
          className="text-lg"
          dangerouslySetInnerHTML={{
            __html: text.replace(
              new RegExp(`(${query})`, 'gi'),
              '<span class="font-bold text-lg text-blue-500">$1</span>',
            ),
          }}
        ></span>
      ) : (
        <span className="text-lg">{text}</span>
      )}
    </>
  )

  const HighlightedDescriptionText: FC<HighlightedDescriptionText> = ({
    text,
    query,
  }) => (
    <div className="w-full overflow-hidden truncate text-gray-500">
      {text?.toLowerCase()?.includes(query) ? (
        <span
          className="text-sm"
          dangerouslySetInnerHTML={{
            __html: text.replace(
              new RegExp(`(${query})`, 'gi'),
              '<span class="font-bold text-sm text-blue-500">$1</span>',
            ),
          }}
        ></span>
      ) : (
        <span className="text-sm">{text}</span>
      )}
    </div>
  )

  const resourceIconType = (item) => {
    if (item.__typename === SEARCH_RESULT_TYPE.COURSE) {
      switch (item.learnerType) {
        case 'CATEGORY':
          return <TagIcon className="h-8 w-8" />
        case 'COURSE':
          return <ClipboardDocumentListIcon className="h-8 w-8" />
        case 'ACTIVITY':
          return <RectangleStackIcon className="h-8 w-8" />
        case 'TASK':
          switch (item.taskType) {
            case 'QUIZ':
              return <PuzzlePieceIcon className="h-8 w-8" />
            case 'VIDEO':
              return <VideoCameraIcon className="h-8 w-8" />
            case 'EMBED_URL':
              return <CodeBracketIcon className="h-8 w-8" />
            case 'DOCUMENT':
              return <DocumentTextIcon className="h-8 w-8" />
            default:
              break
          }
          break
        default:
          break
      }
    }
    if (item.__typename === SEARCH_RESULT_TYPE.KNOWLEDGE_BASE) {
      switch (item.kbType) {
        case 'ARTICLE':
          return <NewspaperIcon className="h-8 w-8" />
        case 'STORAGE_OBJECT':
          return <CircleStackIcon className="h-8 w-8" />
        case 'URL':
          return <LinkIcon className="h-8 w-8" />
        case 'DOCUMENT':
          return <DocumentTextIcon className="h-8 w-8" />
        default:
          return <TagIcon className="h-8 w-8" />
      }
    }
    if (item.__typename === SEARCH_RESULT_TYPE.PROCESS_MAP) {
      return <MapIcon className="h-8 w-8" />
    }
    if (item.__typename === SEARCH_RESULT_TYPE.HUBDASHLAYOUT) {
      return <Squares2X2Icon className="h-8 w-8" />
    }
  }

  const StyledBreadcrumbs = styled(Breadcrumbs)`
    .MuiButtonBase-root {
      &:hover {
        background-color: transparent;
      }
      svg {
        width: 14px;
        color: #9ca3af;
      }
    }
  `

  return (
    <>
      {!hideTabLabel && (
        <div className="mb-3 flex flex-row items-center pt-3">
          {category.icon}
          <h6 className="ml-1 text-sm font-medium uppercase leading-4 tracking-wider text-gray-400">
            {category.name}
          </h6>
        </div>
      )}
      <List
        dense
        disablePadding
        className={data.length > 0 ? 'bg-white' : 'bg-none'}
        sx={{
          width: '100%',
          borderRadius: 2,
          border: '1px solid #e5e7eb',
        }}
      >
        {dataFiltered.length > 0 ? (
          dataFiltered.map((item: SearchResultWithUrl, index) => (
            <div
              key={item.entityId + '+' + item?.learnerType}
              data-testid={`global-search-item-${index}`}
            >
              <ListItem
                className="group"
                disablePadding
                secondaryAction={
                  item.__typename === SEARCH_RESULT_TYPE.MEMBERSHIP && (
                    <>
                      {item?.phone && (
                        <IconButton
                          className={
                            isMobile ? 'mr-1' : 'mr-2 hover:bg-indigo-100'
                          }
                          aria-label="phone"
                          disableRipple
                          href={`tel:${item.phone}`}
                        >
                          <PhoneIcon width={20} height={20} />
                        </IconButton>
                      )}
                      {item?.email && (
                        <IconButton
                          className={
                            isMobile
                              ? ''
                              : item.__typename ===
                                  SEARCH_RESULT_TYPE.MEMBERSHIP
                                ? 'hover:bg-indigo-100'
                                : 'mr-5 hover:bg-indigo-100'
                          }
                          aria-label="email"
                          disableRipple
                          href={`mailto:${item.email}`}
                        >
                          <EnvelopeIcon width={20} height={20} />
                        </IconButton>
                      )}
                    </>
                  )
                }
              >
                <ListItemButton
                  disableRipple
                  className="p-2"
                  onClick={() => {
                    handleCategoryNavigation({
                      __typename: item.__typename,
                      entityId: item.entityId,
                      handleClose,
                      index,
                      url: item?.url,
                      trackEvent,
                      learnerType: item?.learnerType,
                      courseId: item?.courseId,
                      activityId: item?.activityId,
                      isEmbed: item?.isEmbed,
                      sectionId: item?.sectionId,
                    })
                    const updatedRecentCards = [
                      item.entityId,
                      ...(recentCardIds?.includes(item.entityId)
                        ? without([item.entityId], recentCardIds)
                        : recentCardIds),
                    ]
                    setRecentCards(updatedRecentCards)
                  }}
                >
                  <div className="min-w-[56px]">
                    {item.__typename === SEARCH_RESULT_TYPE.MEMBERSHIP && (
                      <UserAvatar src={item.avatarUrl} />
                    )}
                    {item.__typename === SEARCH_RESULT_TYPE.HOME_LINKS && (
                      <>
                        {item.avatarUrl ? (
                          <IconButton disableRipple>
                            <Avatar
                              alt={item.title}
                              src={item.avatarUrl}
                              imgProps={{
                                loading: 'lazy',
                              }}
                              className="h-8 w-8"
                            />
                          </IconButton>
                        ) : (
                          <IconButton disableRipple>
                            <ArrowTopRightOnSquareIcon className="h-8 w-8 text-gray-500" />
                          </IconButton>
                        )}
                      </>
                    )}
                    {(item.__typename === SEARCH_RESULT_TYPE.COURSE ||
                      item.__typename === SEARCH_RESULT_TYPE.KNOWLEDGE_BASE ||
                      item.__typename === SEARCH_RESULT_TYPE.PROCESS_MAP ||
                      item.__typename === SEARCH_RESULT_TYPE.HUBDASHLAYOUT) && (
                      <IconButton
                        disableRipple
                        className="rounded-full bg-indigo-100 p-2 text-indigo-600"
                      >
                        {resourceIconType(item)}
                      </IconButton>
                    )}
                  </div>
                  <ListItemText
                    disableTypography
                    className="!my-0"
                    primary={
                      <>
                        {item.breadcrumb && (
                          <StyledBreadcrumbs
                            separator="/"
                            aria-label="global search breadcrumb"
                            maxItems={3}
                            onClick={(e) => e.stopPropagation()}
                            sx={{
                              '.MuiBreadcrumbs-li .MuiButtonBase-root:': {
                                padding: '0px',
                                backgroundColor: 'transparent',
                                color: 'inherit',
                              },
                            }}
                          >
                            {item.breadcrumb.map((title) => (
                              <div key={index} className="flex items-center">
                                <Typography
                                  key={title}
                                  className="text-xs text-gray-400"
                                >
                                  {title}
                                </Typography>
                              </div>
                            ))}
                          </StyledBreadcrumbs>
                        )}
                        {item.__typename === SEARCH_RESULT_TYPE.HOME_LINKS && (
                          <p className="text-xs text-gray-400">
                            {item.sectionName}
                          </p>
                        )}
                        <div className="flex items-center">
                          <HighlightedTitleText
                            text={item.title}
                            query={query}
                          />
                        </div>
                      </>
                    }
                    secondary={
                      <>
                        <HighlightedDescriptionText
                          text={item.description}
                          query={query}
                        />
                        {item.__typename ===
                          SEARCH_RESULT_TYPE.KNOWLEDGE_BASE && (
                          <HighlightedDescriptionText
                            text={
                              item.articleText
                                ? item.articleText
                                : item.documentText
                            }
                            query={query}
                          />
                        )}
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>

              {index !== dataFiltered?.length - 1 && <Divider />}
            </div>
          ))
        ) : (
          <Empty
            className="rounded-md bg-white py-6"
            title="No Results Found"
          />
        )}
      </List>
    </>
  )
}

export const GlobalSearchResults: FC<GlobalSearchResultsProps> = ({
  data,
  query,
  handleClose,
  tabVal,
  hideTabLabel = false,
}) => {
  return (
    <>
      {tabVal === 'all' ? (
        <>
          <GoogleSuggestionsContent query={query} />
          {categories.map((category, index) => {
            const filteredData = data.filter(
              (item) => item.__typename === category.value,
            )
            return (
              <div key={category.value + index}>
                <ListContent
                  data={filteredData}
                  value={tabVal}
                  query={query}
                  category={category}
                  handleClose={handleClose}
                  hideTabLabel={hideTabLabel}
                />
              </div>
            )
          })}
        </>
      ) : (
        categories
          .filter((category) => category.value === tabVal)
          .map((category, index) => (
            <div key={category.value + index}>
              <ListContent
                data={data}
                value={tabVal as string}
                query={query}
                category={category}
                handleClose={handleClose}
                hideTabLabel={hideTabLabel}
              />
            </div>
          ))
      )}
    </>
  )
}
