import { FC } from 'react'

import { CustomButtonProps } from '../Library/Button'
import Button from '../Library/Button/Button'

const PageHeaderIconButton: FC<
  CustomButtonProps & {
    children: JSX.Element
    buttonDataTestId?: string
  }
> = ({ children, ...rest }) => {
  return (
    <Button
      fullWidth={false}
      variant="outlined"
      color="secondary"
      className="min-w-[0px] p-1"
      {...rest}
    >
      {children}
    </Button>
  )
}

export default PageHeaderIconButton
