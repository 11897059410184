import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import GoogleIcon from 'src/lib/icons/GoogleIcon/GoogleIcon'

const performSearch = (searchQuery: string) => {
  const searchUrl = `https://www.google.com/search?q=${searchQuery}`
  openWindowWithBlockCheck(searchUrl, '_blank')
}

export default function GoogleSearchResultList(props) {
  const { query, googleSuggestions } = props

  const { trackEvent } = useAnalytics()

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        borderRadius: 2,
        border: '1px solid #e5e7eb',
        marginBottom: '10px',
      }}
      disablePadding
    >
      {googleSuggestions.slice(0, 2)?.map((suggestion, index) => (
        <div key={index}>
          <ListItem
            disablePadding
            component="a"
            onClick={() => {
              performSearch(suggestion)
              trackEvent('Search', 'GS click suggestion', {
                suggestionIndex: index,
              })
            }}
            secondaryAction={
              <IconButton edge="end" aria-label="linkto" disableRipple>
                <ArrowUpRightIcon width={20} height={20} />
              </IconButton>
            }
            data-testid={`google-suggestion-${index}`}
          >
            <ListItemButton disableRipple>
              <ListItemIcon className="min-w-[47px]">
                <GoogleIcon className="h-8 w-8" />
              </ListItemIcon>
              <ListItemText primary={suggestion}></ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
      <ListItem
        disablePadding
        component="a"
        href={`https://google.com/search?q=${query}`}
        target="_blank"
        secondaryAction={
          <IconButton edge="end" aria-label="delete" disableRipple>
            <ArrowUpRightIcon width={20} height={20} />
          </IconButton>
        }
        onClick={() => {
          trackEvent('Search', 'GS Search the Web')
        }}
      >
        <ListItemButton disableRipple>
          <ListItemIcon className="min-w-[47px]">
            <GoogleIcon className="h-8 w-8" />
          </ListItemIcon>
          <ListItemText
            primary={`Search the Web for "${query}"`}
          ></ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  )
}
