import React, { FC } from 'react'

import { GlobalSearch, GlobalSearchVariables } from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { GlobalSearchResults } from 'src/components/GlobalSearch/GlobalSearchResults'
import { GoogleSuggestionsContent } from 'src/components/GlobalSearch/GlobalSearchTabs'
import EmptyResults from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query GlobalSearch($query: String!, $showAllStatuses: Boolean!) {
    globalSearch(input: { query: $query }, showAllStatuses: $showAllStatuses) {
      entityId
      title
      description
      ... on KnowledgeBaseSearchResult {
        url
        breadcrumb
        kbType
        articleText
        documentText
      }
      ... on MembershipSearchResult {
        avatarUrl
        phone
        email
      }
      ... on LearnerCourseSearchResult {
        breadcrumb
        learnerType
        taskType
        courseId
        activityId
      }
      ... on HomeLinksSearchResult {
        url
        avatarUrl
        sectionName
        isEmbed
        sectionId
      }
      ... on ProcessMapsSearchResult {
        breadcrumb
      }
    }
  }
`

export const Loading = ({ query }) => {
  if (query === '') {
    return null
  } else {
    return (
      <div className="min-h-80 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    )
  }
}

export const Empty = ({ query, tabVal }) => {
  if (query === '' || !query) {
    return (
      <EmptyResults
        className="py-6 bg-white rounded-md"
        title="No Results Found"
      />
    )
  } else if (tabVal === 'all') {
    return (
      <div className="min-h-80 pt-3">
        <GoogleSuggestionsContent query={query} />
      </div>
    )
  } else {
    return (
      <EmptyResults
        className="py-6 bg-white rounded-md"
        title="No Results Found"
      />
    )
  }
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<GlobalSearch, GlobalSearchVariables> & {
  query: string
  handleClose: () => void
  tabVal: string
  hideTabLabel?: boolean
}

export type SearchResult = Props['globalSearch'][0]

export const Success: FC<Props> = ({
  globalSearch,
  query,
  handleClose,
  tabVal,
  hideTabLabel = false,
}) => {
  return (
    <GlobalSearchResults
      data={globalSearch}
      query={query}
      handleClose={handleClose}
      tabVal={tabVal}
      hideTabLabel={hideTabLabel}
    />
  )
}
