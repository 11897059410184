/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react'

import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Drawer, InputAdornment, TextField } from '@mui/material'

import GlobalSearchCell from 'src/components/GlobalSearch/GlobalSearchCell'
import { GlobalSearchTabs } from 'src/components/GlobalSearch/GlobalSearchTabs'

import useAnalytics from '../../lib/hooks/useAnalytics'
import useDebounce from '../../lib/hooks/UseDebounce'
import IconButton from '../Library/IconButton'
import Modal from '../Modal'

export default function GlobalSearchModal({
  searchVal,
  setSearchVal,
  open,
  handleClose,
  isMobile,
}) {
  let debouncedSearchTerm = ''
  debouncedSearchTerm = useDebounce(searchVal.toLocaleLowerCase(), 300)

  const { trackEvent } = useAnalytics()
  const [tabVal, setTabVal] = useState<string>('all')

  if (isMobile) {
    const anchor = 'top'
    return (
      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          variant="temporary"
          open={open}
          onClose={() => {
            setSearchVal('')
            handleClose()
            trackEvent('Search', 'GS close modal')
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
              backgroundColor: '#f9fafb',
            },
          }}
        >
          <div className="p-2">
            <TextField
              fullWidth
              autoFocus
              id="mobile-search"
              value={searchVal}
              placeholder="Search the Hub..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MagnifyingGlassIcon
                      width={24}
                      height={24}
                      color="#a7a8a9"
                      strokeWidth="2px"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="close search"
                    onClick={() => {
                      handleClose()
                      trackEvent('Search', 'GS close search')
                    }}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </IconButton>
                ),
              }}
              variant="outlined"
              onChange={(e) => {
                setSearchVal(e.target.value)
              }}
            />
            <GlobalSearchCell
              query={debouncedSearchTerm}
              handleClose={handleClose}
              tabVal={tabVal}
              showAllStatuses={false}
            />
          </div>
        </Drawer>
      </React.Fragment>
    )
  } else
    return (
      <Modal
        open={open}
        closeButtonVisible={false}
        onClose={() => {
          setSearchVal('')
          handleClose()
        }}
        aria-labelledby="modal-global-search"
        aria-describedby="modal-modal-description"
        dialogClassName="!bg-gray-50 overflow-hidden"
        childClassName="mt-0"
        className="modal-root-global-search !w-full max-w-4xl"
      >
        <div className="min-h-80">
          <div className="px-3 pt-3">
            <TextField
              fullWidth
              autoFocus
              id="desktop-search"
              value={searchVal}
              className="bg-white"
              placeholder="Search the Hub..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MagnifyingGlassIcon
                      width={24}
                      height={24}
                      color="#a7a8a9"
                      strokeWidth="2px"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchVal.length > 0 && (
                      <IconButton
                        aria-label="close search"
                        onClick={() => {
                          setSearchVal('')
                          trackEvent('Search', 'GS close search')
                        }}
                        disableRipple
                        sx={{
                          ml: 1,
                          '&.MuiButtonBase-root:hover': {
                            bgcolor: 'transparent',
                          },
                        }}
                      >
                        <XMarkIcon className="h-6 w-6" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={(e) => {
                setSearchVal(e.target.value)
              }}
            />
            <GlobalSearchTabs
              isMobile={false}
              tabVal={tabVal}
              setTabVal={setTabVal}
            />
          </div>
          <div className="max-h-[75vh] overflow-auto px-3 pb-3">
            <GlobalSearchCell
              query={debouncedSearchTerm}
              handleClose={handleClose}
              tabVal={tabVal}
              showAllStatuses={false}
            />
          </div>
        </div>
      </Modal>
    )
}
